.bordertop {
  border-top: 3px solid orange;
}

.logo-div img {
  width: 13rem;
}
b.company_area {
  font-weight: 700;
  font-size: 16px;
  color: white;
}
.address-div span {
  font-size: 14px;
}

.address-div img {
  width: 17px;
}

.address-div i {
  width: 14px;
}

.address-div strong {
  font-size: 12px;
}

.footer-column ul li a {
  font-size: 14px;
  color: rgb(3, 3, 3);
}

.footer-column ul li a:hover {
  font-size: 14px;
  color: rgba(254, 110, 1, 1);
}

.button-div11 button {
  font-size: 13px;
  width: 16rem;
  color: rgba(75, 64, 64, 0.46);
}

.button-div12 button {
  background: linear-gradient(
    90deg,
    rgba(254, 110, 1, 1) 0%,
    rgba(249, 146, 4, 1) 100%
  );
  font-size: 13px;
  width: 16rem;
}

.btn-submit {
  border: 1px solid #1a1d30;
  color: #1a1d30;
}

.btn-submit:hover {
  background-color: #1a1d30;
  color: white;
}

.btn-submit:active {
  background-color: #333b63;
}

.footer-text {
  height: 3.77rem;
}

.hover-underline-animation {
  display: inline-block;
  position: relative;
}

.hover-underline-animation:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #0087ca;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.hover-underline-animation:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

p.last-text {
  font-size: 14px;
  text-align: justify;
}

.media-icon {
  position: fixed;
  width: auto;
  height: 47px;
  bottom: 40px;
  text-align: center;
  left: 15px;
  z-index: 100;
}

.media-size {
  width: auto;
  height: 35px;
}

.footer_section h5 {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #fe8a01;
}

.mail_size {
  max-width: 1.5rem;
}

.phone_size {
  max-width: 1rem;
}

.footer_text p,
.footer_text ul li {
  font-weight: 500;
  font-size: 16px;
  color: #bdbdbd;
}
b.company_area {
  font-weight: 700;
  font-size: 16px;
  color: white;
}
.end_section {
  background-color: #000000;
  padding-bottom: 6%;
}

.partion {
  border: 1px solid #fff;
  width: 95%;
}

.privacy_policy .copy_link {
  font-size: 12px;
  font-weight: 400;
  line-height: 13px;
  color: #6c6c6c;
}

.flag_imgs {
  width: 2.5rem;
  height: 2.5rem;
}
.footer_container h4 {
  font-size: 16px;
  color: #bdbdbd;
}
.social_img {
  width: 1.3rem;

  height: 1.3rem;
}

.col_imp:hover {
  color: #fe8a01 !important;
}

@media only screen and (max-width: 768px) {
  .forcoppyRight {
    font-size: 12px;
  }

  .logo-div img {
    width: 10rem;
  }
  .privacy_policy .copy_text {
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    color: #bdbdbd;
  }

  .flag_imgs {
    width: 2.5rem;
    height: 2.5rem;
  }

  .footer_section .largHead_text {
    font-size: 18px;
  }

  .footer_section .smallHead_text {
    font-size: 12px;
    color: #fff;
    line-height: normal;
  }

  .footer_text ul li {
    font-size: 11px;
    color: #fe8a01 !important;
  }

  .footer_text p,
  .footer_text ul li {
    color: #fff;
  }

  .footer_text p {
    font-size: 12px;
    text-align: justify;
  }

  .footer_text .mobile_text {
    font-size: 10px;
  }

  .mail_size {
    width: 19.2px;
    height: 18px;
  }
  .col_imp {
    color: #fff !important;
  }

  .phone_size {
    width: 17.7px;
    height: 17.77px;
  }

  .social_img {
    width: 20px;
    height: 20px;
  }

  .footer_container h4 {
    font-size: 15px;
    color: #fff;
    line-height: 14px;
  }

  .privacy_policy .copy_text {
    font-size: 12px;
  }
}

@media (min-width: 1100px) and (max-width: 1900px) {
  .logo-div img {
    width: 12rem;
  }

  .address-div span {
    font-size: 1opx;
  }

  .address-div img {
    width: 12px;
  }

  .address-div i {
    width: 10px;
  }

  .address-div strong {
    font-size: 8px;
  }

  .footer-column ul li a {
    font-size: 10px;
    color: rgb(3, 3, 3);
  }

  .footer-column ul li a:hover {
    font-size: 10px;
    color: rgba(254, 110, 1, 1);
  }

  .button-div11 button {
    font-size: 9px;
    width: 16rem;
    color: rgba(75, 64, 64, 0.46);
  }

  .button-div12 button {
    background: linear-gradient(
      90deg,
      rgba(254, 110, 1, 1) 0%,
      rgba(249, 146, 4, 1) 100%
    );
    font-size: 9px;
    width: 16rem;
  }

  .btn-submit {
    border: 1px solid #1a1d30;
    color: #1a1d30;
  }

  .btn-submit:hover {
    background-color: #1a1d30;
    color: white;
  }

  .btn-submit:active {
    background-color: #333b63;
  }

  .footer-text {
    height: 3rem;
  }

  .hover-underline-animation {
    display: inline-block;
    position: relative;
  }

  .hover-underline-animation:after {
    content: "";
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #0087ca;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }

  .hover-underline-animation:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }

  p.last-text {
    font-size: 10px;
    text-align: justify;
  }

  .media-icon {
    position: fixed;
    width: auto;
    height: 38px;
    bottom: 30px;
    text-align: center;
    left: 10px;
    z-index: 100;
  }

  .media-size {
    width: auto;
    height: 27px;
  }

  .footer_section h5 {
    font-weight: 600;

    font-size: 19px;
    line-height: 26px;
    color: #fe8a01;
  }

  .mail_size {
    max-width: 1.5rem;
    height: 1.5rem;
  }

  .phone_size {
    max-width: 1rem;
    height: 1rem;
  }

  .footer_text p,
  .footer_text ul li {
    font-weight: 500;
    font-size: 16px;
    color: #bdbdbd;
    line-height: 20px;
  }

  .end_section {
    background-color: #000000;
    padding-bottom: 2%;
  }

  .partion {
    border: 1px solid #fff;
    width: 85%;
  }

  .privacy_policy .copy_text {
    font-size: 16px;
    font-weight: 400;
    line-height: 10px;
    color: #bdbdbd;
  }

  .privacy_policy .copy_link {
    font-size: 15px;
    font-weight: 400;
    line-height: 16px;
  }
  .privacy_policy .copy_link:hover {
    color: white;
  }

  .flag_imgs {
    width: 2.5rem;
    height: 2.5rem;
  }

  .social_img {
    width: 1.3rem;
    height: 1.3rem;
  }

  .facebook_icon {
    width: 1.3rem;
    height: 1.3rem;
  }
}

.transform {
  transform: translate(10px, -18px) !important;
}

.col_imp {
  color: #bdbdbd;
}
