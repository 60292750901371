body {
  font-family: "Poppins" !important;
  overflow-x: hidden;
}
.custom-container {
  width: 100%;
  width: -moz-available;
  margin-right: auto;
  margin-left: auto;
}
.tabelcontent {
  background-color: #e99d44;
  border-radius: 15px;
}
.FirstHeading h1 {
  font-size: 2.5rem;
}
.Content-Heading h3 {
  font-size: 1.7rem;
}
.card123::before {
  position: absolute;
  top: -19px;
  left: -35px;
  content: "";
  width: 49px;
  height: 35px;
  background-repeat: no-repeat;
}
.card123::after {
  position: absolute;
  bottom: 25pxpx;
  right: -40px;
  content: "";
  width: 54px;
  height: 39px;
  background-repeat: no-repeat;
  background-size: 38px;
}
.limitTextHeight {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  height: 3rem;
  -webkit-box-orient: vertical;
}
p.para-text-forReveiw {
  text-align: center;
  font-size: 12px;
  text-align: justify;
}
.card1 a {
  border-radius: 0.44rem;
  background-color: orange;
  color: white;
  padding: 3px 6px;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
}
.show-desktop {
  display: block !important;
}
.show-mobile {
  display: none !important;
}
#myBtn {
  display: none;
  position: fixed;
  bottom: 40px;
  right: 30px;
  z-index: 99;
  font-size: 24px;
  border: none;
  outline: none;
  background-color: rgb(62 160 193);
  color: rgb(255, 255, 255);
  cursor: pointer;
  border-radius: 10px;
}
.sticky {
  position: fixed;
  top: 0;
  z-index: 9999;
  width: 100%;
  width: -moz-available;
  background-color: black;
  animation: smoothScroll 1s forwards;
}
@keyframes smoothScroll {
  0% {
    transform: translateY(-40px);
  }
  100% {
    transform: translateY(0px);
  }
}
@media only screen and (max-width: 768px) {
  .show-desktop {
    display: none !important;
  }
  .show-mobile {
    display: block !important;
  }
}
@media (max-width: 480px) {
  .FirstHeading h1 {
    font-size: 1.6rem;
  }
  .Content-Heading h3 {
    font-size: 20px;
  }
  .card123::before {
    top: -45px;
    left: 13px;
  }
  .card123::after {
    display: none;
  }
  #myBtn {
    display: none !important;
  }
}
@media (min-width: 1800px) {
  .containerFor1600px {
    max-width: 1600px !important;
    margin: 0 auto;
  }
}
@media (min-width: 1800px) {
  .containerForFullWidth {
    max-width: fit-content;
    margin: 0 auto;
  }
}
@media (min-width: 1400px) {
  .Homepage {
    max-width: 1590px !important;
  }
}
@media (min-width: 1400px) {
  .healthcare {
    max-width: 1692px !important;
  }
}
@media (min-width: 1400px) {
  .Technology {
    max-width: 1600px !important;
  }
}
@media (min-width: 1400px) {
  .Banking {
    max-width: 1700px !important;
  }
}
@media (min-width: 1400px) {
  .Automotive {
    max-width: 1570px !important;
  }
}
header {
  background-color: #f4f4f4;
  border-radius: 0px 0px 50px 50px;
}
.navbar {
  text-transform: uppercase;
}
.navbar,
.nav-item a {
  color: #000;
}
.navbar ul {
  width: 70%;
}
.navbar .nav-item a {
  text-decoration: none;
  font-size: 16px !important;
}
.sub_banner_heading {
  font-size: 22px !important;
  font-weight: 500;
  line-height: 23px !important;
}
.org_text {
  border-bottom: 8px solid #fe8a01 !important;
}
.white-txt {
  border-bottom: 7px solid white;
}
.banner-heading1 {
  font-size: 60px;
  font-weight: 700;
  line-height: 59px;
}
.rowthtech {
  background: #f97a04;
  border-radius: 0px 23px 23px 0px;
  font-size: 24px;
}
.box1_heading {
  font-size: 54px;
  font-weight: 600;
  line-height: 53px;
}
.box1_subheading {
  font-size: 17px;
  font-weight: 500;
  line-height: 24px;
}
.box1_phara {
  font-size: 16px;
  font-weight: 500;
}
.inner_phara_headings {
  font-size: 24px;
  font-weight: 600;
}
.inner_phara_content {
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
}
.width {
  width: 100%;
  width: -moz-available;
}
.content,
.content_inner_row {
  height: 100%;
  height: -moz-available;
}
.icon_size {
  max-width: 3.5rem;
}
.fb_size {
  max-width: 2.1rem;
}
.twittter_size {
  max-width: 4.1rem;
}
.privacy_policy {
  font-size: 26px;
  font-weight: 500;
  color: #bdbdbd;
}
.privacy_policy p {
  font-size: 16px;
  font-weight: 400;
  color: #6c6c6c;
}
.height {
  height: 100%;
  height: -moz-available;
}
.retail_section h5 {
  font-size: 24px;
  font-weight: 600;
}
.footer_height {
  height: 59.5rem;
}
.webHead_Div {
  transform: translate(0px, -36px);
}
.develpoment {
  font-weight: 700;
  font-size: 2em;
}
.software_develpoment a {
  background: rgba(217, 217, 217, 0.1);
  border: 5px solid #ffffff;
  border-radius: 109px;
  font-weight: 600;
  font-size: 1em;
}
.software_develpoment {
  top: 25%;
  left: 0;
}
.btn {
  border-color: #ffffff;
  border-radius: 9px;
}
.about_heading {
  font-size: 26px;
}
.about_para {
  font-weight: 500;
  font-size: 0.9em;
}
.about_content h2 {
  font-weight: 700;
  font-size: 18px;
}
.about_content p {
  font-weight: 400;
  text-align: justify;
  font-size: 17px;
}
.img-1 {
  width: 100%;
  width: -moz-available;
  height: 100%;
  height: -moz-available;
}
.section_5 {
  background-color: #16171c;
  height: 456px;
  width: 100%;
  width: -moz-available;
}
.nav_data .nav-link:focus,
.nav-link:hover {
  color: #ffa500 !important;
}
.section_5 .global {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 40px;
  display: block;
}
.global {
  font-size: 40px;
  font-weight: 600;
  line-height: 36px;
}
.inner_box h4 {
  color: #fe8a01;
  font-weight: 400;
  font-size: 1.33rem;
}
.solu_paraText {
  font-size: 0.669rem;
}
.services-section h4 {
  font-weight: 500;
  font-size: 22px;
}
.Transformation {
  font-weight: 500;
  border-bottom: 5px solid#FE8A01;
  font-size: 1em;
}
.forTabs {
  background-color: white;
}
.tab-content h6 {
  font-size: 16px;
  font-weight: 700 !important;
}
.nav-pills .nav-link {
  color: black;
  border-right: 3px solid grey !important;
  border-radius: 0 !important;
  font-size: 18px;
}
.nav-pills .nav-link.active:after {
  content: "";
  clip-path: polygon(0 0, 0% 100%, 100% 49%);
  -webkit-clip-path: polygon(0 0, 0% 100%, 100% 49%);
  clip-path: polygon(0 0, 0% 115%, 65% 49%);
  background-color: #f9a004;
  transition: all 0.5s ease;
  width: 9%;
  height: 28%;
  right: 0;
  position: absolute;
  transform: translate(25px, 0px);
}
.nav-pills .nav-link.active {
  color: orange !important;
  border-right: 3px solid orange !important;
  background-color: transparent !important;
}
.tab-content .API-Development {
  background-color: #fff8ed;
}
.height {
  height: 100%;
}
.retail_section h5 {
  font-size: 19px;
  font-weight: 600;
  line-height: 20px;
}
.retail_section1 {
  border-radius: 24px !important;
}
.widthh {
  width: 100%;
  width: -moz-available;
  color: #6c6c6c;
}
.retail_section1:hover {
  background-color: orange;
  -webkit-transition: all 1s 0s ease;
  -moz-transition: all 0.47s 0s ease;
  -o-transition: all 0.47s 0s ease;
  transition: all 0.47s 0s ease;
}
.activenav {
  background-color: orange;
  transition: all 0.5s ease;
}
.retail_section1:hover .margin_work {
  margin-top: 5px !important;
}
.retail_section1:hover .viewMore_btn {
  display: block !important;
}
.three_line {
  transform: translate(243px, 28px);
}
.form-controll {
  border: 1px solid #8a8a8a;
  border-radius: 10px;
  font-size: 15px;
  font-weight: 500;
  line-height: 30px;
  color: #838383;
}
.button {
  background: #000000;
  border-radius: 110px;
  color: white;
  font-size: 36px;
  font-weight: 500;
}
.border-backgroundd {
  border-top-right-radius: 30px;
  border-top-left-radius: 30px;
  width: fit-content;
  border-width: 0.1px;
  border-style: solid;
  border-bottom-width: 0;
}
.bg_color {
  background: linear-gradient(
    180deg,
    rgba(217, 217, 217, 0) -14.99%,
    rgba(217, 217, 217, 0.270833) 30.48%,
    #dadada 92.78%
  );
}
.inner_frmDiv {
  z-index: 2;
}
.background_grd::after {
  content: "";
  height: 100%;
  height: -moz-available;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background: linear-gradient(
    180deg,
    rgba(217, 217, 217, 0) -6.99%,
    rgba(217, 217, 217, 0.270833) -21.52%,
    #dadada 49.78%
  );
  border-left: 0;
  border-right: 0;
  border-top-right-radius: 30px;
  border-top-left-radius: 30px;
}
.show_desktop {
  display: block;
}
.show_mobile {
  display: none;
}
@media screen and (max-width: 768px) {
  .banner-heading {
    font-size: 20px;
    line-height: 98%;
  }
  .sub_banner_heading {
    font-size: 13px;
    line-height: 98%;
  }
  .show_desktop {
    display: none;
  }
  .show_mobile {
    display: block;
  }
  .content_outer_box {
    position: relative !important;
  }
  .rowthtech {
    font-size: 12px;
    font-weight: 600;
  }
  header {
    border-radius: 0px;
    left: 0;
    right: 0;
    z-index: 999;
    top: 0;
    position: sticky;
    top: 0px;
    box-shadow: rgb(0 0 0) 0px 15px 20px -20px;
  }
  .nav_pos {
    position: relative;
  }
  .develpoment {
    font-size: 14px;
    font-weight: 600;
    line-height: 14px;
  }
  .software_develpoment a {
    border: 2px solid #ffffff;
    font-size: 11px;
    line-height: 17px;
  }
  .rowth_logo {
    width: 9rem;
  }
  .electronic h5 {
    font-size: 18px;
  }
  .box_size p {
    font-size: 12px;
    text-align: justify;
  }
  .inner_phara_headings {
    font-size: 13px;
    line-height: 97%;
  }
  .inner_phara_content {
    font-size: 10px;
    line-height: 15px;
    text-align: justify;
  }
  .mob_width {
    width: 100%;
    width: -moz-available;
  }
  .line_color {
    border-bottom: 2px solid #888888 !important;
  }
  .box_color {
    background-color: #f4f4f4;
    border-radius: 10px;
  }
  .box_size h6 {
    color: #000;
  }
  .inner_phara_mobile {
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    text-align: justify;
  }
  .tele {
    border-bottom: 2px solid #bbbbbb;
  }
  .phar {
    background-color: #e9edf3;
    border-radius: 16px 16px 0px 0px;
  }
  .image_size {
    width: 3rem;
  }
  .rowthtech_sol {
    font-size: 12px;
    padding: 0px 8px;
    font-weight: 600;
  }
  .box1_heading {
    font-size: 22px;
    line-height: 24.76px;
  }
  .box1_subheading {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }
  .image_sizing {
    width: 2.7rem;
  }
  .image_sizings {
    width: 1.8rem;
  }
  .banner-heading1 {
    font-size: 20px;
    line-height: 20px;
  }
  .box2_heading {
    font-size: 1.3em;
    font-weight: 600;
    line-height: 17px;
  }
  .box1_phara {
    font-size: 12px;
    line-height: 12.36px;
  }
  .mobile_inner_phara_headings {
    font-size: 20px;
    font-weight: 600;
    line-height: 20px;
  }
  .mobile_inner_phara_content {
    font-size: 15px;
    font-weight: 500;
    line-height: 23px;
    text-align: inherit;
  }
  .custom_financial_box {
    background-color: white;
    height: auto;
    width: auto;
  }
  .text_j {
    text-align: justify;
  }
}
@media (min-width: 1400px) {
  .container-xl {
    max-width: 1570px !important;
  }
}
.img-sec img {
  width: 100%;
  width: -moz-available;
  border-radius: 18px;
}
.heading-a {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 48px;
  text-transform: uppercase;
}
.org-txt1-1 {
  border-bottom: 6px solid #f97a04;
  color: #000 !important;
}
.org-txt1 {
  border-bottom: 6px solid #f97a04;
}
.heading-b {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 64px;
  line-height: 55.68px;
  width: fit-content;
  text-transform: uppercase;
  color: #000 !important;
}
.heading-c {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 44px;
  line-height: 98%;
  text-transform: capitalize;
}
.line-img2 img {
  margin-left: 55rem;
  transform: translate(9px, 190px);
}
.button {
  background: #000000;
  border-radius: 110px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 23px;
  text-align: center;
  text-transform: capitalize;
  color: #ffffff;
  border: 0px;
}
.clients-heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 36px;
  text-transform: uppercase;
}
.para-2-1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400 !important;
  font-size: 24px;
  line-height: 104%;
  text-align: center;
  text-transform: capitalize;
  color: #ffffff !important;
}
.brdr {
  background-color: #ffffff;
  border-radius: 10px;
}
.brdr-1 {
  background-color: #f97a04;
  border-radius: 10px;
}
.image-radius1-1 {
  border-radius: 100px;
  height: 10rem;
  width: 10rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.img_section {
  bottom: 30px;
  height: 3rem;
}
.image-position {
  transform: translate(0px, 25px);
  display: flex;
  flex-direction: column;
  align-items: center;
}
.names {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 5%;
  text-align: center;
  text-transform: capitalize;
}
.name-bold {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 24%;
  text-align: center;
  text-transform: capitalize;
}
.main-container {
  background: #ececec;
  height: 26em;
}
.main-container::after {
  content: (" ");
  z-index: 2;
  width: 100%;
  width: -moz-available;
  position: absolute;
  top: 12px;
  bottom: 12px;
  right: 12px;
  transform: translate(0px, -61px) scale(1.05);
  height: 317px;
}
.profile_cont .sameProfile_div:nth-child(2) {
  z-index: 2;
  background-color: #f97a04;
  transform: translate(0px, -61px) scale(1.05);
  height: 317px;
}
.clients1-1 {
  font-weight: 500;
  font-size: 48px;
}
.footer_text p {
  font-weight: 500;
  font-size: 20px;
}
.footer_text ul li {
  font-weight: 500;
  font-size: 20px;
}
.apps h4 {
  font-weight: 600;
  font-size: 40px;
  line-height: 60px;
  color: #bdbdbd;
}
.privacy_policy span {
  font-size: 26px;
  font-weight: 500;
  line-height: 39px;
}
.privacy_policy p {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: #6c6c6c;
}
.inner_div {
  z-index: 2;
  position: relative;
}
.form_container::after {
  content: "";
  position: absolute;
  bottom: 0;
  height: 13rem;
  left: 0;
  right: 0;
  z-index: 1;
}
@media only screen and (max-width: 768px) and (min-width: 360px) {
  .banner-heading {
    line-height: 98%;
  }
  .sub_banner_heading {
    font-size: 13px;
    line-height: 98%;
  }
  .show_desktop {
    display: none;
  }
  .show_mobile {
    display: block;
  }
  .content_outer_box {
    position: relative !important;
  }
  .rowthtech {
    font-size: 12px;
  }
  .org_text1-1 {
    border-bottom: 3px solid orange;
  }
  header {
    border-radius: 0px;
    left: 0;
    right: 0;
    z-index: 999;
    top: 0;
    position: sticky;
    top: 0px;
    box-shadow: rgb(0 0 0) 0px 15px 20px -20px;
  }
  .nav_pos {
    position: relative;
  }
  .img-sec img {
    width: 100%;
    width: -moz-available;
    border-radius: 18px;
    height: 100%;
    height: -moz-available;
  }
  .org-txt1-1 {
    border-bottom: none !important;
    font-weight: 600;
  }
  .heading-a {
    font-size: 12px;
  }
  .heading-b::before {
    content: "";
    width: 91%;
    background-color: #f97a04;
    position: absolute;
    height: 3px;
    top: -7px;
    transform: translateY(65%);
  }
  .heading-b {
    font-size: 24px;
    border-bottom: 3px solid #f97a04;
  }
  .textbold {
    font-weight: 600;
  }
  .heading-c {
    font-size: 12px;
    width: 132px;
  }
  .tablecontents {
    border: 1px solid#8A8A8A;
  }
  .form_container::before {
    position: absolute;
    bottom: -2px;
    height: 7rem;
    left: -2px;
    right: -2px;
    z-index: 1;
    border-radius: 12px;
    border-bottom: 4px solid rgb(255, 255, 255) !important;
    border-left: 22px solid rgb(255, 255, 255) !important;
    border-right: 22px solid rgb(255, 255, 255) !important;
  }
  .form_container::after {
    background-size: contain;
    background-repeat: no-repeat;
    width: 120%;
    height: 14rem;
    left: -12%;
    bottom: -119px;
    z-index: -1;
  }
  .inner_div {
    z-index: 2;
    position: relative;
  }
  .org-txt1 {
    border-bottom: 3px solid #f97a04;
    font-weight: 700;
  }
  .clients-heading {
    font-size: 20px;
    font-weight: 100 !important;
  }
  input text {
    font-size: 12px !important;
  }
  .comma1-1 {
    width: 37px;
    height: 27px;
  }
  .profile_cont .sameProfile_div:nth-child(2) {
    z-index: 2;
    background-color: #f97a04;
    transform: translate(30px, -67px) scale(0.9);
    height: 10rem;
    width: 82vw;
  }
  .main-container {
    background: none;
    height: 13rem;
  }

  .para-2-1 {
    font-size: 12px !important;
  }
  .name-bold {
    font-size: 12px;
    font-weight: 900;
  }
  .image-radius1-1 {
    width: 56%;
    height: 50%;
  }
  textarea::placeholder,
  input::placeholder {
    font-size: 12px;
  }
  textarea {
    height: 14vh !important;
  }
  .newrow4 {
    flex-wrap: nowrap !important;
  }
  .colimg img {
    width: 76px;
    height: 40px;
  }
  .colimg1 img {
    width: 76px;
    height: 15px;
  }
  .colimg2 img {
    width: 76px;
    height: 35px;
  }
}
@media (min-width: 1400px) {
  .container-xl {
    max-width: 1570px !important;
  }
}
.case-study-btn {
  font-size: 32px;
  font-weight: 600;
  line-height: 36px;
  border-radius: 10px;
  font-weight: 500;
  color: #fff;
  border: none;
  background: #00ab4f;
}
@media only screen and (max-width: 768px) and (min-width: 360px) {
  .img1-6 img {
    width: 50%;
  }
  .img1-1 img {
    width: 14px;
  }
}
@media (min-width: 1400px) {
  .container-xl {
    max-width: 1626px !important;
  }
}
.content_outer_box {
  bottom: 0;
  top: 0;
  color: #fff;
}
.content,
.content_inner-row {
  height: 100%;
  height: -moz-available;
}
section .content_outer_box h1 {
  font-size: 64px;
  line-height: 1;
  color: #fff;
}
section .sub-banner-heading {
  line-height: 1;
  font-size: 36px;
  color: #fff;
  font-weight: 500;
}
.sec_1 p,
.sec_2 p,
.section_3 p {
  font-size: 1em;
  font-weight: 500;
  line-height: 115%;
}
.sec_1 .heading span {
  font-size: 24px;
  font-weight: 600;
  background: #f97a01;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  color: #fff;
  padding: 4px 14px;
}
.sec_1 .heading h2,
.sec_2 .heading h2 {
  font-size: 48px;
  font-weight: 600;
}
.financial_services h6 {
  font-size: 32px;
  font-weight: 600;
}
.bordr_btm {
  border-bottom: 3px solid #000;
}
.sec_2 h6 {
  font-size: 24px;
  font-weight: 500;
}
.bordr_btm {
  border-bottom: 3px solid #000;
}
.section_3 .sec_2_heading h2 {
  font-size: 54px;
  font-weight: 600;
}
.section_3 .sec_2_heading p {
  font-size: 24px;
  font-weight: 500;
  width: fit-content;
  line-height: 26px;
}
.section_3 h3 {
  font-size: 24px;
  font-weight: 600;
}
.section_3 .arrow_img {
  width: 16rem;
}
@media only screen and (max-width: 768px) and (min-width: 360px) {
  .hero-img {
    height: 9rem;
  }
  .width {
    height: -moz-available;
    border-radius: 12px;
  }
  section .content_outer_box h1 {
    font-size: 20px;
  }
  section .sub-banner-heading {
    font-size: 13px;
    color: #000;
  }
  .sec_1 .heading span {
    font-size: 12px;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
  .sec_1 .heading h2,
  .sec_2 .heading h2 {
    font-size: 20px;
    font-weight: 600;
  }
  .headings {
    font-weight: 600;
  }
  .sec_1 p,
  .sec_2 p,
  .section_3 p {
    font-weight: 400;
    font-size: 10px;
    line-height: 13px;
    text-align: justify;
  }
  .financial_services h6 {
    font-size: 10px;
    font-weight: 600;
  }
  .img1-9 img {
    width: 38px;
  }
  .financial_services::before {
    content: " ";
    background: #e3e7f0;
    width: 314px;
    top: -23px;
    height: 165%;
    bottom: 51px;
    right: -137px;
    z-index: -1;
    position: absolute;
    border-radius: 12px;
  }
  .para-s p {
    word-spacing: normal !important;
    font-weight: 400;
  }
  .img1-11 img {
    width: 70%;
  }
  .sec_2 h6 {
    font-size: 12px;
  }
  .section_3 .arrow_img {
    width: 16rem;
  }
  .img1-12 img {
    width: 7rem;
  }
  .img1-13 img {
    width: 19rem;
  }
  .section_3 h3 {
    font-size: 24px;
  }
  .img1-14 img {
    width: 3rem;
  }
  .img1-15 img {
    width: 77%;
  }
}
@media (min-width: 1400px) {
  .container-xl {
    max-width: 1480px !important;
  }
}
.content_outer_box {
  bottom: 0;
  top: 0;
  color: #fff;
}
.content,
.content_inner-row {
  height: 100%;
  height: -moz-available;
}
.rowths {
  font-weight: 600;
  color: #fff;
}
section .content_outer_box h1 {
  font-size: 74px;
  line-height: 1;
  color: #fff;
  font-weight: 600;
  border-top: 6px solid #fff;
  border-bottom: 6px solid #fff;
}
section .sub-banner-heading {
  line-height: 1;
  font-size: 36px;
  color: #fff;
  font-weight: 500;
}
.section_1 h2 {
  font-size: 48px;
  line-height: 72px;
}
.section_1 .content_div .btn-outline-dark h6 {
  font-weight: 600 !important;
  border-width: 3px;
  border-radius: 10px;
}
.section_1 .content_div h6 {
  font-size: 24px;
  font-weight: 500;
}
.case-study-btn {
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  color: #000;
  border-radius: 10px;
  border: 3px solid #000;
}
.section_1 .blue_text span {
  color: #0c2b95;
}
.section_1 .crypto-sec h2 {
  font-size: 64px;
  font-weight: 700;
  text-transform: uppercase;
  padding-right: 7rem;
}
.section_1 .crypto-sec p {
  font-size: 32px;
  font-weight: 400;
  line-height: 48px;
}
.bg_color {
  background-color: #f2f2f2;
}
@media only screen and (max-width: 768px) and (min-width: 360px) {
  section .content_outer_box h1 {
    font-size: 20px;
    border-bottom: none;
    border-top: none;
  }
  section .sub-banner-heading {
    font-size: 12px;
    font-weight: 300;
  }
  .rowths {
    font-weight: 300;
  }
  .width {
    height: 100%;
    height: -moz-available;
  }
  .hero-img {
    height: 146px;
  }
  .section_1 h2 {
    font-size: 20px;
    line-height: normal;
  }
  .section_1 p {
    font-size: 10px;
    line-height: normal;
  }
  .section_1 .content_div span {
    font-size: 20px;
    line-height: normal;
  }
  .section_1 .content_div h6 {
    font-size: 10px;
  }
  .case-study-btn {
    font-size: 12px;
    line-height: normal;
    font-weight: 700;
    color: #fff;
    border-color: #fe5c01;
    background-color: #fe5c01;
  }
  .bgcolor4::before {
    content: "";
    background: #f5f5f5;
    width: 90%;
    position: absolute;
    height: 106%;
    z-index: -1;
    border-radius: 14px;
    top: -9px;
    right: 19px;
  }
  .btn-outline-secondary {
    background-color: transparent;
    border-radius: 22px;
  }
}
@media (min-width: 1400px) {
  .container-xl {
    max-width: 1526px !important;
  }
}
.content_outer_box2-1 {
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 26px;
}
section .content_outer_box2-1 h1 {
  font-size: 72px;
  line-height: 1;
  color: white;
  font-family: "system-ui";
  font-weight: 500;
}
section .content_outer_box2-1 h1 .bold-text2 {
  font-weight: 800 !important;
}
section .sub-banner-heading2 {
  line-height: 1;
  color: white;
  font-size: 40px;
}
.service-section-row2 .service-content2 h2 {
  font-size: 54px;
  font-weight: 700;
  line-height: 98%;
}
.service-section-row2 .service-content2 h2 .orange-heading2 {
  color: #fe8a01;
}
.service-section-row2 .service-content2 p {
  font-weight: 400;
  color: #000000;
  line-height: 48px;
  text-align: justify;
  font-size: 1.22em !important;
}
.service-section-row2 .col-11 .content-wrapper-row,
.company_services .bottom_line {
  border-bottom: 3px solid #000;
}
.content-wrapper-row {
  padding: 5rem 0rem !important;
}
.company_services h4 {
  font-size: 36px;
  font-weight: 600;
  line-height: 98%;
}
.company_services p {
  font-family: "Poppins";
  font-size: 32px;
  font-weight: 400;
  line-height: 41px;
  letter-spacing: 0em;
  text-align: justify;
}
.grey-line::before {
  content: "";
  position: absolute;
  top: 0;
  left: 7%;
  height: 75%;
  width: 13px;
  z-index: -1;
  background: #e3e3e3;
}
.grey-line::after {
  content: "";
  position: absolute;
  top: 94%;
  right: 8%;
  height: 82%;
  width: 13px;
  z-index: -1;
  transform: translateY(-100%);
  background: #e3e3e3;
}
.company_services .col-9 .text {
  text-align: right;
}
.company_services {
  padding: 5rem 0rem;
}
.product_section .col-4 .product_img img {
  width: 100%;
  width: -moz-available;
}
.product_section .col-4 h5 {
  font-size: 22px;
  font-weight: 600;
}
.product_section .col-4 p {
  font-size: 20px;
  line-height: 98%;
}
.product_section h2 {
  font-size: 54px;
  font-weight: 700;
  color: #000;
}
.product_section h2 .orange_border2-1 {
  border-bottom: 7px solid #fe8a01;
}
.qualitystyle h5 {
  font-weight: 900;
  font-size: 22px;
  line-height: 98%;
  color: #000000;
}
.qualitystyle p {
  font-weight: 100;
  font-size: 20px;
  color: #000;
}
@media only screen and (min-width: 0px) and (max-width: 768px) {
  .banner-heading2 {
    line-height: 98%;
  }
  .sub_banner_heading2 {
    font-size: 13px;
    line-height: 98%;
  }
  .show_desktop {
    display: none;
  }
  .show_mobile {
    display: block;
  }
  .content_outer_box2-1 {
    position: relative !important;
    border-radius: 13px !important;
  }
  .rowthtech {
    font-size: 12px;
  }
  .org_text {
    border-bottom: 3px solid orange;
  }
  header {
    border-radius: 0px;
    left: 0;
    right: 0;
    z-index: 999;
    top: 0;
    position: sticky;
    top: 0px;
    box-shadow: rgb(0 0 0) 0px 15px 20px -20px;
  }
  .nav_pos {
    position: relative;
  }
  .section .sub-banner-heading2 {
    font-size: 6px;
  }
  section .content_outer_box2-1 h1 {
    font-size: 20px;
    line-height: 1;
  }
  section .sub-banner-heading2 {
    line-height: 1;
    font-size: 14px;
  }
  #width {
    width: 100%;
    width: -moz-available;
    height: 100%;
    height: -moz-available;
    border-radius: 10px;
  }
  .hero-img {
    border-radius: 20px;
    height: 11rem !important;
  }
  .text {
    font-size: 24px;
    font-weight: 700;
    line-height: 24px;
    color: #fe8a01;
  }
  .orange-heading2 {
    font-size: 23px;
    white-space: nowrap;
    color: #000000 !important;
  }
  .proff {
    text-align: center !important;
  }
  .service-section-row2 .service-content2 p {
    font-size: 16px;
    line-height: 24px;
  }
  .border-btm {
    border-bottom: 4px solid black;
  }
  .bordr-btm2-1 {
    border-bottom: 4px solid black;
  }
  .imgwidth {
    width: 43px;
    height: auto;
  }
  #imgwidth {
    height: 6rem !important;
  }
  .pics1 {
    width: 53px;
  }
  .grey-line::before {
    display: none;
  }
  .grey-line::after {
    display: none;
  }
  .product_section h2 {
    font-size: 24px;
  }
  .fontsize2 {
    font-size: 12px;
  }
  .product_section h2 .orange_border2-1 {
    border-bottom: none !important;
    color: #000000 !important;
  }
  .orange1text {
    color: #fe8a01 !important;
    font-size: 24px;
  }
  .devloper_section h2 {
    font-size: 32px;
  }
  .devlops {
    border-bottom: 3px solid #fe8a01 !important;
    display: inline-block !important;
  }
  .hire_imgs::after {
    content: "";
    background-repeat: no-repeat;
    width: 30px;
    height: 30px;
    position: absolute;
    top: -20px;
    right: 107px;
    background-size: contain;
  }
  h2#hires::before {
    content: "";
    width: 70%;
    height: 4px;
    background: #fe8a01;
    position: absolute;
    bottom: 0;
    left: 50%;
    z-index: -1;
    transform: translateX(-50%);
  }
  .devloper_section .para-11-1 {
    font-size: 14px;
    color: #000000;
    font-weight: 600;
  }
  .devloper_section .para-22-1 {
    font-size: 12px;
  }
  .tabelcontent {
    background-color: #fe8a01;
    border-radius: 15px;
  }
  .text-1 {
    font-size: 16px;
    line-height: 92%;
  }
  .size {
    font-weight: 500;
    font-size: 14px;
  }
  ul li {
    white-space: wrap !important;
  }
}
.topBanner_font {
  font-size: 64px;
}
.heading_ParentFont {
  font-size: 20px;
}
.sub_headingParentText {
  font-size: 25px;
}
@media (min-width: 1300px) and (max-width: 1900px) {
  .heading_ParentFont {
    font-size: 17px;
  }
}
@media (min-width: 1000px) and (max-width: 1300px) {
  .heading_ParentFont {
    font-size: 13px;
  }
}
@media (min-width: 769px) and (max-width: 1000px) {
  .heading_ParentFont {
    font-size: 10px;
  }
}
@media (min-width: 768px) {
  .custom-container {
    max-width: 750px;
  }
}
@media (min-width: 880px) {
  .custom-container {
    max-width: 840px;
  }
}
@media (min-width: 992px) {
  .custom-container {
    max-width: 980px;
  }
}
@media (min-width: 1200px) {
  .custom-container {
    max-width: 1120px;
  }
}
@media (min-width: 1400px) {
  .custom-container {
    max-width: 1370px;
  }
}
@media (min-width: 1600px) {
  .custom-container {
    max-width: 1560px;
  }
}
@media (min-width: 1800px) {
  .custom-container {
    max-width: 1720px;
  }
}
/* -------------------------- Loader css ---------------------- */
.cenerter {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.loader {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #f9a004;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.policy_page {
  text-align: justify;
}
.heading_data_portion {
  font-size: 18px;
  line-height: 24px;
  font-weight: 700 !important;
}
.para_data_portion {
  font-size: 17px;
  line-height: 24px;
  font-weight: 400;
}
